/* body {
    background-color: #fff;
} */
input[type="radio"],
input[type="checkbox"] {
  all: revert;
  accent-color: #e0201c;
}
.checkbox-login {
  width: 15px !important;
  height: 14px !important;
  accent-color: #e0201c;
}
.signup-wrapper input:focus {
  outline: none !important;
  /* background-color: #f5f5f5 !important; */
  box-shadow: none !important;
}
.PhoneInput {
  background-color: #f5f5f5 !important;
  border-radius: 5px;
}
.PhoneInputCountry {
  /* padding-left: 7px !important;
    margin-right: 0 !important; */
}
.PhoneInputCountrySelectArrow {
  display: none !important;
}
.PhoneInputInput {
  padding-left: 10px !important;
}
.login-img {
  padding-inline-start: 5vw;
  margin-bottom: 50px;
}
.login-main {
  display: flex;
  justify-content: space-between;
}
.logimg {
  width: 130px;
  /* height: auto; */
  /* margin-top: 6%;   */
}
.login-log{
  display: flex;
  justify-content: space-around;
  text-align: center;
  gap: 20px;
}
.login-wrapper{
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
}

/* .uppend{
   border-top: 18px solid #e0211d;
  border-bottom: 18px solid #e0211d;
  border-radius: 28px;
} */

.form-log {
  
  /* border-top: 18px solid #e0211d;
  border-bottom: 18px solid #e0211d; */
  max-width: 450px;
  width: 100%;
  height: auto;
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  /* padding: 2%; */
  text-align: left;
  /* text-align: center; */
  /* border-radius: 10px; */
  /* margin-top: 3%; */
  /* padding-inline-start: 9vw; */
}
/* .login-wrapper {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 34.5px);
    overflow: hidden;
    margin-top: 34.5px;
    width: 100%;
} */


.login-right{
  height: 100px;
  width: 100px;
  background-color: #e0201c;

}
.login-wrapper .right {
  flex: 1;
}
.login-wrapper .login-left {
  flex: 1;
  padding-top: 17px;
  /* height: calc(100vh - 34.5px); */
  overflow: auto;
  /* background-color: #fff; */
}
/* Style scrollbar */
.login-wrapper .login-left::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.login-wrapper .login-left::-webkit-scrollbar-thumb {
  background-color: #fff; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Border radius of the scrollbar thumb */
}

.login-wrapper .login-left::-webkit-scrollbar-track {
  background-color: #fff; /* Color of the scrollbar track */
}
.login-wrapper .login-left .login-logo {
  /* padding-inline: 20px; */
  display: flex;
  justify-content: center;
}
.login-wrapper .login-left .login-logo img {
}
.login-wrapper .login-left .left {
  /* width: 60%; */
  margin-inline: auto;
  padding-top: 30px;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}
/* .blue {
  float: right;
  margin-right: 10px;
  margin-top: 20px;
  font-size: 14px;
}
.blue a {
  text-decoration: none;
} */
.form-log .email-label {
  color: #e0201c;
}
.form-log label {
  margin-top: 16px;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #a7a6a6;
  margin-bottom: 10px;
}
.form-log label .red {
  color: #a7a6a6;
  margin-left: 13px;
  cursor: pointer;
}
.form-log .pass {
  color: #a7a6a6;
}
.form-log h4 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #3e3e3e;
}
.form-log p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #3e3e3e;
  white-space: nowrap;
  /* padding-bottom: 15px; */
}
.form-log p .redirect {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #e0201c;
}
.input-container {
  position: relative;
}
.input-container .one {
  position: absolute;
  left: 12px;
  top: 19px;
}
.input-container .pass-icon {
  position: absolute;
  left: 12px;
  top: 15px;
}
.input-container .eye {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.mt-20 {
  margin-top: 20px;
}
.container-forgot {
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
  flex-direction: row-reverse;
}
.container-forgot .remember {
  display: flex;
  gap: 5px;
  align-items: center;
}
.container-forgot .forgot p {
  color: #e0201c;
  cursor: pointer;
}
.dont-account p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #3e3e3e;
  margin-top: 14px;
}
.dont-account a {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #e0201c;
}
.form-log input {
  width: 100%;
  height: 45px;
  outline: none;
  padding: 0.375rem 35px !important;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: none;
}

.form-log .phone .PhoneInputInput {
  width: 100%;
  height: 45px;
  outline: none;
  padding: 0.375rem 8px !important;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: none;
}
.container-social {
  display: flex;
  gap: 18px;
  justify-content: center;
  margin-top: 25px;
}
.container-social .one {
  border: 1px solid #d9d9d9;
  padding: 10px 12px;
  border-radius: 100%;
  cursor: pointer;
}
.container-social .two {
  border: 1px solid #dc4b3a;
  padding: 10px 12px;
  border-radius: 100%;
  cursor: pointer;
}
.container-social .three {
  border: 1px solid #3b5997;
  padding: 10px 16px;
  border-radius: 100%;
  cursor: pointer;
}
.form-log input::placeholder {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #3e3e3e;
}
.logbtn {
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 5px;
  background: #e0201c;
  color: #fff;
  text-align: center;
  /* margin-top: 5%; */
}
.mar-top {
  margin-top: 30px;
}
.resize-form {
  width: 60%;
  margin-bottom: 5%;
}
.proimg {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: auto;
  /* margin-left: 42%; */
  margin-top: 2%;
}
.upbox {
  margin-top: -40px;
}
.icon-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  outline: none;
  font-size: 2em;
}
.resize-btn {
  /* width: 20%; */
  background: #fff;
  color: #000;
  margin-left: 10px;
  border: 2px solid #e0201c;
}
.resize-btn:hover {
  background: #e0201c;
  color: #fff;
  border: none;
}
.active_btn {
  background: #e0201c;
  color: #fff;
  border: none;
}
.active_btn:hover {
  background: #fff;
  color: #000;
  border: 2px solid #e0201c;
}
.p-item {
  /* margin-top:6%; */
  text-align: center;
  /* margin-left: 30px; */
  margin: 0;
}
.w-8 {
  width: 70%;
  float: right;
}
.hide {
  display: none;
}
.signup-wrapper {
  /* display: flex;
  justify-content: space-between; */
  /* height: calc(100vh - 34.5px); */
  /* overflow: hidden; */
  /* margin-top: 34.5px; */
  /* background-color: #fff; */
  padding: 50px 0px;
}
.signup-wrapper .signup-left {
  /* flex: 1; */
  padding-top: 17px;
  /* background-color: #000; */
  /* background-image: url('background-networking.png');   */
  /* height: calc(100vh - 34.5px); */
  /* overflow: auto; */
  max-width: 542px;
  margin-inline: auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  border-top: 20px solid #e0211d;
/*  */

}

/* Style scrollbar */
.signup-wrapper .signup-left::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  
}

.signup-image{
  height: auto;
  width: auto;
text-align: center;
margin-top: 20px;  
}

.signup-wrapper .signup-left::-webkit-scrollbar-thumb {
  background-color: #fff; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Border radius of the scrollbar thumb */
}

.signup-wrapper .signup-left::-webkit-scrollbar-track {
  background-color: #fff; /* Color of the scrollbar track */
}
/* scrollbar-color: red transparent; */
.signup-wrapper .signup-left .left {
  /* width: 70%; */
  margin-inline: auto;
  /* padding-top: 30px; */
  /* padding-top: 10px; */
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
 

  /* height: 100%; */
}
.signup-wrapper .signup-left .left .signup-form {
  width: 100%;
  max-width: 550px;
  margin: 0px;
}
.signup-wrapper .signup-left .left .signup-form .input-con {
  position: relative;
}
.signup-wrapper .signup-left .left .signup-form .input-con .pass-icon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 54%;
  left: 4%;
}
.signup-wrapper .signup-left .left .signup-form .input-con .hide-icon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 10px;
}
.signup-wrapper .signup-left .left .signup-form .input-con .eye {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 5px;
  font-size: 18px;
  cursor: pointer;
}
.signup-wrapper .right {
  flex: 1;
}
.signUpper{
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* text-align: center; */
  /* gap: 10px; */

}
.signUpper p { 
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  width: 250px;
}
.hrs{
  width: 2.5px;
    height: 40px;
    background-color: #e0211d;
    margin: 0;
    border: none;
}
.signup-wrapper .signup-logo {
  /* padding-inline: 10px; */
  /* padding-top: 19px; */
  /* display: flex; */
  justify-content: center;
}
.signup-wrapper .signup-btn {
  background: #e0201c;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0px;
}
.signin-wrapper-btn {
  text-align: center;
}
.error-message {
  color: red;
  font-size: 12px;
}
/* .input-con .input::placeholder {
    color: #3E3E3E;
    opacity: 0.7;
    pointer-events: none;
  }
  .input-con .input::-ms-input-placeholder { 
    color: #3E3E3E;
  } */
.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.forgot-pasword {
  /* margin-top: 70px;
  padding-inline: 50px; */
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
}
.forgot-pasword .top-logo {
  display: flex;
  justify-content: center;
  /* gap: 24px; */
  margin-bottom: 40px;
}
.forgot-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: calc(100vh - 107.56px); */
  flex-direction: column;
  width: 100%;
  margin-inline: auto;
  max-width: 550px;
}
.forgot-input-container .input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.forgot-input-container .input-wrapper label {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #3e3e3e;
  margin-bottom: 10px;
}
.forgot-input-container .input-wrapper input {
  width: 100%;
  /* max-width: 500px; */
  /* min-width: 500px; */
  height: 45px;
  outline: none;
  padding: 0.375rem 20px !important;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: none;
}
.forgot-input-container .forgot-btn-wrapper button {
  margin-top: 10px !important;
  /* max-width: 500px; */
  width: 100%;
  /* min-width: 500px; */
  padding: 8px 40px;
  border-radius: 8px;
  background-color: #e0201c;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 16px;
}
.forgot-input-container .forgot-btn-wrapper {
  width: 100%;
  margin-top: 20px;
}
.signup-wrapper .react-tel-input .form-control {
  padding-left: 48px !important;
  width: 100%;
  height: 45px;
  outline: none;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: none;
}
.signup-wrapper .react-tel-input .flag-dropdown {
  border: none;
}
.input-change-pass-con {
  position: relative;
}
.input-change-pass-con .eye {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 5px;
  font-size: 18px;
  cursor: pointer;
}

.confirm-pasword{
  /* margin-top: 70px; */
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
}
.confirm-paswordd{
  margin-top: 70px;
  max-width: 542px;
  margin-inline: auto;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
padding: 18px;
border-radius: 30px;
border-top: 20px solid #e0211d;


}





.confirm-pasword .top-logo {
  display: flex;
  justify-content: center;
  /* gap: 24px; */
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .resize-form {
    width: 90%;
    margin-bottom: 5%;
  }
  .form-log {
    width: 100%;
    padding-inline: 30px;
  }
  .signUpper p{
    text-align: center;
  }
  .signup-wrapper .signup-left .left .signup-form {
    width: 100%;
    /* padding-inline: 30px; */
  }
  .signup-wrapper .signup-logo {
    padding-inline: 30px;
  }
  .forgot-input-container {
    width: 100%;
  }

  .forgot-pasword {
    padding-inline: 30px;
  }
  .confirm-pasword{
    padding-inline: 30px;
  }
  .confirm-paswordd{
    padding-inline: 30px;
    /* margin-top: 70px; */
  }
}
@media (max-width: 767px) {
  .label-signup {
    display: none;
  }
  .signup-wrapper .signup-btn {
    margin-top: 16px;
    margin-left: 0px !important;
  }
  .login-wrapper .right {
    display: none;
  }
  .signup-wrapper .right {
    display: none;
  }
  .signup-wrapper .signup-left .left .signup-form .input-con .pass-icon {
    left: 20px;
  }
  
}
@media (max-width: 450px) {
  .form-log {
    width: 90%;
    padding-inline: 20px;
  }
  .signup-wrapper .signup-left .left .signup-form {
    width: 100%;
  }
  .signup-wrapper .signup-logo {
    padding-inline: 20px;
  }

  .forgot-pasword {
    padding-inline: 20px;
  }
  .confirm-pasword{
    padding-inline: 20px;
  }
  .confirm-paswordd{
    padding-inline: 20px;
  }
}





/* /////////////////////Login Responsove////////////////////////// */


/* For mobile screens, we'll make the form take the full width and stack items vertically */
@media (max-width: 768px) {
  .login-log {
    flex-direction: column;
    gap: 10px;
  }
  .responce{
    width: 300px;
    height: 200px;
  }

  .form-log {
    max-width: 100%; /* Form takes full width on smaller screens */
    margin: 0 20px; /* Add margin to prevent the form from touching screen edges */
  }

  .login-wrapper {
    top: auto;
    transform: none; /* Remove center transformation for smaller screens */
    left: auto;
    position: static;
    padding: 0;
    width: 100%; /* Let the wrapper take full width */
  }
  .hrs{
    display: none;
  }

  .login-wrapper .login-left {
    padding-top: 0;
  }

  .login-wrapper .left {
    flex-direction: column; /* Stack elements vertically */
    padding-top: 10px;
  }

  

  /* Adjust heading sizes for smaller screens */
  .form-log h4 {
    font-size: 20px;
    line-height: 30px;
  }

  .form-log p {
    font-size: 12px;
    line-height: 18px;
  }

  /* Adjust label fonts for readability on smaller screens */
  .form-log label {
    font-size: 12px;
  }
}

/* Additional styles for extra small screens (mobile under 576px) */
@media (max-width: 576px) {
  .form-log {
    padding: 15px;
  }

  .form-log h4 {
    font-size: 18px;
    line-height: 28px;
  }

  .form-log p {
    font-size: 12px;
  }

  .login-wrapper .left {
    padding: 10px;
  }
}