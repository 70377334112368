@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
input[type="radio"],
input[type="checkbox"] {
  all: revert;
  accent-color: #e0201c;
}
ul {
  list-style: none;
  padding: 0;
}
footer input,
button,
textarea {
  all: unset;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
a {
  text-decoration: none;
  color: #2b2b2b;
}
a:hover {
  text-decoration: none;
  color: #2b2b2b;
}
p {
  margin: 0;
  padding: 0;
}

.error-message {
  color: red !important;
  margin-top: 5px !important;
  font-size: 14px !important;
  text-align: start !important;
}
.error-input {
  border: 1px solid red !important;
  border-radius: 3px;
}
.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #e0201c;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
.loaderWrapper-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh);
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
.css-1jqq78o-placeholder {
  margin-left: 20px !important;
}
.css-1dimb5e-singleValue {
  margin-left: 25px !important;
}
.dropdown-menu {
  min-width: 80vw;
}
.navbar-nav {
  flex-direction: row !important;
}
.nav-link {
  padding: 0px !important;
}
.list-group-item {
  border: none !important;
  font-weight: 200;
  padding: 0px !important;
}
.list-item-custom-class {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important; */
  font-weight: 800;
}
.css-188mx6n-MuiSlider-root {
  color: #e0201c !important;
}
.css-qbdosj-Input {
  margin-left: 25px !important;
}
.css-166bipr-Input {
  margin-left: 25px !important;
}


@media screen and (max-width: 768px) {
  .css-1domaf0 {
    width: 100% !important;
  }
  .swiper-slide {
    margin-right: 14px;
  }
}
