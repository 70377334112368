.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 60px;
  margin-top: 25px;
}

.header .shop-icon-cart {
  position: relative;
}

.header .shop-icon-cart span {
  position: absolute;
  top: -1px;
  right: -4px;
  width: 12px;
  height: 12px;
  background-color: #e0201c;
  border-radius: 100%;
}

.header .left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header .left ul {
  /* display: flex; */
  align-items: start;
  gap: 40px;
  margin: 0;
}

.header .left ul li {
  font-family: "Poppins", sans-serif;
  color: #2b2b2b;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  cursor: pointer;
}

.header .left ul li a {
  text-decoration: none;
  color: #2b2b2b;
}

.header .center {}

.header .right {}

.header .right ul {
  display: flex;
  gap: 34px;
  /* align-items: center; */
}

.header .right ul li {
  cursor: pointer;
}

.header .right ul .login-btn {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  border: 2px solid #2b2b2b;
  padding: 6px 17px;
  border-radius: 5px;
}

.header .right .input-search-con {
  position: relative;
}

.header .right .input-search-con .search-input {
  border: 1px solid #d4d4d4;
  background-color: rgba(249, 250, 251, 1);
  padding: 6px 18px;
  /* padding-inline-start: 40px; */
  border-radius: 6px;
  outline: none;
  min-width: 300px;
}

.header .right .search-results {
  position: absolute;
  /* top: 100%; */
  /* left: 0; */
  z-index: 1000;
  max-width: 300px;
  width: 100%;
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 8px;
  border: 1px solid #f1efef;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 200px;
  overflow-y: auto;
}

.header .right .search-results::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

.header .right .search-results::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the scrollbar track */
}

.header .right .search-results::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar thumb */
  border-radius: 5px;
  /* Rounded corners of the scrollbar thumb */
}

.header .right .search-results::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar thumb on hover */
}

.header .right .search-results .no-data-found {
  font-family: "Poppins", sans-serif;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* .header .right .search-results .search-item:nth-child(1) {
  margin-top: 8px;
} */
.header .right .search-results .search-item {
  display: flex;
  justify-content: start;
  margin-bottom: 8px;
  padding: 5px 3px 3px 3px;
  gap: 8px;
}

.header .right .search-results .search-item:hover {
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header .right .search-results .search-item .image-container img {
  width: 30px;
  border-radius: 8px;
  height: 30px;
}

.header .right .search-results .search-item .product-info {}

.header .right .search-results .search-item .product-info h3 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;

  color: #3e3e3e;
}

.header .right .search-results .search-item .product-info p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  color: #3e3e3e;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header .right .input-search-con svg {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #3e3e3e;
}

.header .language-converter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .language-converter button {
  background: #fff;
  padding: 0px 6px;
  font-size: 16px;
}

.header .language-converter button:nth-child(1) {
  border-radius: 30px 0 0 30px;
}

.header .language-converter button:nth-child(2) {
  border-radius: 0px 30px 30px 0px;
}

.header .language-converter .active-lng {
  background-color: #e11f1c;
  color: #fff;
}

footer {
  background: rgba(6, 7, 24, 1);

  padding-block: 1em;
  color: #f6f6f6;
  padding: 40px 0px;
  margin-top: 80px;
  /* background-image: url(/public/assets/images/footer-bg.svg); */
}

/* footer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/public/assets/images/footer-bg.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  opacity: 0.5; 
} */
footer .flex-list {
  display: flex;
  align-items: center;
  gap: 1em;
}

footer .flex-list img {
  width: 25px;
  height: auto;
}

footer .joint-input {
  display: flex;
  background: #303030;
  border-radius: 5px;
  min-height: 2.5rem;
}

footer .joint-input input {
  flex: 1 1;
  padding-inline: 1em;
  min-width: 0;
  background-color: white;
  color: black;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

footer .button.primary-button {
  background-color: #e0201c;
  color: #f6f6f6;
  transition: all 0.3s ease;
}

footer .button {
  gap: 0.5em;
  padding: 0.5em 1em;
  border-radius: 5px;
  /* border-radius: 30px; */
  padding: 0.3em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  font-weight: 600;
}

footer .para {
  color: rgba(255, 255, 255, 1);

  font-size: 1rem;
  font-weight: 400;
  line-height: 14px;
  font-family: "Poppins", sans-serif;
  line-height: 26px;
}

footer .card-title {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);

  margin-top: 15px;
  width: 100%;
}

.testimonial-sec {
  padding: 77px 0px;
}

.testimonial-sec .heading-con {
  margin-bottom: 49px;
}

.testimonial-sec .main-head {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  color: #2b2b2b;
}

.testimonial-sec .sub-head {
  font-family: "Bebas Neue", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 54px;
  text-align: center;
  color: #2b2b2b;
}

.testimonial-sec .testimonial-card {
  display: flex;
  /* justify-content: space-between; */
  gap: 80px;
}

.testimonial-sec .testimonial-card .desc {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #8d8d8d;
  margin-top: 33px;
}

.testimonial-sec .testimonial-card .name {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: #2b2b2b;
}

.testimonial-sec .testimonial-card .category-btn-prev-next {
  display: flex;
  gap: 12px;
  margin-top: 44px;
}

.testimonial-sec .testimonial-card .category-btn-prev-next svg {
  color: #fff;
}

.testimonial-sec .testimonial-card .category-btn-prev-next .category-prev {
  background-color: #2b2b2b;
  cursor: pointer;
}

.testimonial-sec .testimonial-card .category-btn-prev-next .category-prev:hover {
  background-color: #e0201c;
}

.testimonial-sec .testimonial-card .category-btn-prev-next .category-next {
  background-color: #2b2b2b;
  cursor: pointer;
}

.testimonial-sec .testimonial-card .category-btn-prev-next .category-next:hover {
  background-color: #e0201c;
}

.testimonial-sec .slick-dots {
  position: absolute;
  bottom: 90px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  left: 50px;
  z-index: -1;
}

.testimonial-sec .slick-dots li:first-child {
  border-radius: 21px 0px 0px 21px;
}

.testimonial-sec .slick-dots li.slick-active {
  background-color: #e0201c;
  border-radius: 21px;
}

.testimonial-sec .slick-dots li {
  background: #d9d9d9;
  margin: 0px !important;
  width: 36px !important;
  height: 5px !important;
  margin: 5px !important;
}

.testimonial-sec .slick-dots li button {
  display: none !important;
}

/* .container-fluid{
  min-width: 1283px;
  height: 400px;
} */


.banner-sec {
  padding: 77px 20px;
  position: relative;
}

.banner-sec .right {
  padding-right: 60px;
}

.banner-sec .right img {
  width: 100%;
}

.banner-sec .left {
  padding-left: 60px;
}

.banner-sec .left h2 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 60px;
  font-weight: 400;
  line-height: 60px;
  text-align: left;
  color: #2b2b2b;
  margin-bottom: 83px;
  width: 100%;
  max-width: 325px;
}

.banner-sec .left span {
  color: #e0201c;
}

.banner-sec .left .button-wrap {}

.banner-sec .left .button-wrap button {
  border-radius: 32px;
  background: #e0201c;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 9px 20px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.banner-sec .social-con {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  gap: 0px;
  border-radius: 10px 0px 0px 10px;
  background-color: #e0201c;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 12px 6px;
  gap: 8px;
}

.banner-sec .social-con svg {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.shop-card-con {
  margin-top: 20px;
  /* display: flex; */
}

.shop-card-con .main-filter-con {
  /* display: flex; */
  gap: 12px;
  margin-top: 12px;
  /* min-height: 0; */
}

.shop-card-con .filter-con {
  flex: 1;
  box-shadow: 4px 3px 14px 0 #0000000a;
  position: sticky;
  top: 0px;
  width: 243.14px;
  display: inline-block;
}

.shop-card-con .filter-con .price-filter-con {
  padding: 0;
}

.shop-card-con .filter-con .price-filter-con .title {
  /* border-bottom: 1px solid #ececec; */
  font-family: "poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 11px;
  text-align: left;
}

.shop-card-con .filter-con .price-filter-body {
  display: flex;
  gap: 5px;
  /* margin-top: 12px; */
}

.shop-card-con .filter-con .price-filter-body .min {
  background-color: #fff;
  border: 1px solid #a8a8a8;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 #ececec;
  color: #404040;
  display: block;
  float: left;
  height: 30px;
  min-width: auto;
  outline: 0;
  padding: 0 7px;
  text-align: left;
  transition: all 0.3s linear;
  width: 70px;
}

.shop-card-con .filter-con .price-filter-body .max {
  background-color: #fff;
  border: 1px solid #a8a8a8;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 #ececec;
  color: #404040;
  display: block;
  float: left;
  height: 30px;
  min-width: auto;
  outline: 0;
  padding: 0 7px;
  text-align: left;
  transition: all 0.3s linear;
  width: 70px;
}

.shop-card-con .filter-con .price-filter-body button {
  background-color: #e0201c;
  padding: 5px 8px;
  border-radius: 4px;
  color: #fff;
  font-family: "poppins", sans-serif;
  font-size: 14px;
  height: 30px;
}

.shop-card-con .filter-con .filter-head {
  color: #212529;
  font-family: "poppins", sans-serif;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  padding: 20px 0;
  text-align: left;
}

.shop-card-con .card-con-main {
  /* flex: 5; */
}

.shop-card-con .marleft {
  /* margin-left: 260px; */
  display: inline-block;
  width: calc(100% - 243.14px);
}

.shop-card-con:nth-child(2) {
  margin-top: 0px;
}

.shop-card-con .head-con {
  display: flex;
  justify-content: end;
  /* margin-bottom: 30px; */
}

.shop-card-con .head-con h2 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 54px;
  text-align: left;
}

.shop-card-con .head-con .filter-selection {
  /* display: flex;
  gap: 20px; */
}

.shop-card-con .head-con .filter-selection label {
  margin-right: 8px;
  font-family: "poppins", sans-serif;
  color: #000000a6;
  font-size: 14px;
}

.shop-card-con .head-con .filter-selection select {
  border-radius: 40px;
  height: 40px;
  background: #fff;
  border: 1px solid #9b9b9b;
  box-shadow: 0 3px 0 0 #fafafa;
  cursor: pointer;
  outline: none;
  padding-inline: 12px;
  min-width: 200px;
}

.shop-card-con .card-con {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
  position: relative; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  position: relative;
}

.shop-card-con .card-con .card-wrap {
  background: #f5f5f5;
  border-radius: 12px;
  /* padding: 8px 6px; */
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  padding: 0px;
}

.shop-card-con .card-con .card-wrap:hover {
  box-shadow: 15px 15px 30px 0px #00000040;
}

.shop-card-con .card-con .card-wrap .top-inner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 250px;
}

.shop-card-con .card-con .card-wrap .top-inner img {
  /* width: 100%;
  object-fit: cover;
  border-radius: 6px; */
  width: 100%;
  /* object-fit: cover; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /* border-radius: 12px; */

}

.shop-card-con .card-con .card-wrap .center {
  padding-top: 20px;
  padding-inline: 15px;
  margin-bottom: 15px;
}

.shop-card-con .card-con .card-wrap .center h2 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 21px;
  /* text-align: center; */
  color: rgba(230, 38, 43, 1);
  min-height: 30px;
}

.shop-card-con .card-con .card-wrap .center p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  /* text-align: center; */
  color: #3e3e3e;
  margin-bottom: 6px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-top: 12px; */
  min-height: 31.98px;
  text-transform: capitalize;
}

.shop-card-con .card-con .card-wrap .center .price {
  /* font-family: "Bebas Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0em;
  color: #3e3e3e; */

  font-family: "Bebas Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0em;
  /* text-align: center; */
  color: #3e3e3e;
  display: flex;
  align-items: center;
  gap: 5px;
}

.shop-card-con .card-con .card-wrap .center .price span {
  font-family: "Poppins", sans-serif;
  color: #e0201c;
  font-size: 20px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: center;

}

.shop-card-con .card-con .card-wrap .bottom .btn-wrapper .btn-coffee {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  background-color: #9d9d9d;
  padding: 8px;
  border-radius: 10px;
  /* margin-top: 15px; */
  width: 100%;
  text-align: center;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.shop-card-con .card-con .card-wrap:hover button {
  background-color: #e0201c !important;
}

.shop-card-con .card-con .card-wrap .top {
  position: relative;
  /* padding: 10px 0px 0px 0px; */
  display: flex;
  justify-content: center;
}

.shop-card-con .card-con .card-wrap .top .right {
  padding: 6px 5px;
  background-color: #d9d9d9;
  border-radius: 30px;
  position: absolute;
  top: 4%;
  right: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.shop-card-con .card-con .card-wrap .top .left {
  background-color: #3e3e3e;
  display: flex;
  position: absolute;
  top: 4%;
  left: 4%;
  border-radius: 5px;
  padding: 2px 10px;
}

.shop-card-con .card-con .card-wrap .top .left p {
  color: #fff;
  margin: 0;
}

.shop-card-con .btn-viewmore {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 31px;
}

.shop-card-con .btn-viewmore button {
  color: #e0201c;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}

.shop-card-con .btn-viewmore img {
  cursor: pointer;
}

.shop-card-con .category-head {
  padding: 50px 0px 20px 0px;
}

.shop-card-con .category-head:not(:first-child) {
  padding: 10px 0px 20px 0px;
}

.shop-card-con .category-head h2 {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
  /* padding-inline: 27px; */
}

.header .right ul li .after-login {
  color: #e0201c;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 7px 14px;
  border: 1.5px solid #e0201c;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  background: none !important;
  cursor: pointer;
}

.header .right ul li .after-login svg path {
  fill: #e0201c;
}

.header .right ul li .dd-options {
  border-radius: 8px;
  background: #fff;
  box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.3);
  position: absolute;
  min-width: 300px;
  right: 0;
  display: none;
}

.header .right ul li .dd-options.show {
  display: inline;
}

.header .right ul li .dd-options ul {
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: start;
  z-index: 100;
  position: relative;
}

.header .right ul li .dd-options ul li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.header .mobile-nav .dd-options ul li,
.header .right ul li .dd-options ul li {
  display: flex;
  align-items: center;
  padding: 5px 40px;
  width: 100%;
  gap: 9px;
  border-bottom: 1px solid #e1e1e1;
  cursor: pointer;
  background: #fff;
}

.header .right ul li .dd-options ul li a {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 9px;
}

header .right ul li a {
  /* color: #fff; */
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  text-decoration: none;
}

.header .right ul li .dd-options ul li .dd-options-left {}

.header .right ul li .dd-options ul li .dd-options-right {}

.header .right ul li .dd-options ul li .dd-options-right h4 {
  color: #3e3e3e;
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin: 0;
}

.header .right ul li .dd-options ul li .dd-options-right p {
  color: #aaa;
  font-family: "Poppins";
  font-size: 9.6px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header .right ul li .dd-options ul li:last-child {
  border-bottom: none;
}

.header .right ul li .dd-options ul li:hover {
  background: linear-gradient(90deg, #3e3e3e 0%, #1e1e1e 100%);
}

.header .right ul li .dd-options ul li:hover h4 {
  color: #fff;
}

.header .mobile-nav .dd-options ul li:last-child:hover,
.header .right ul li .dd-options ul li:last-child:hover {
  background: #fff;
}

.header .mobile-nav .dd-options ul li:last-child,
.header .right ul li .dd-options ul li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.header .right ul li .dd-options ul li .button {
  text-align: center;
  width: 100%;
}

.header .right ul li .dd-options ul li .button button {
  border-radius: 8px;
  background: #e0201c;
  color: #fff;
  font-family: "Poppins";
  font-size: 11.2px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 50px;
}

.header .hamburger-mobile {
  display: none;
}

.header .mobile-nav {
  display: none;
}

.header .sub-cat-mega-menu-con {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0px 8px 0px;
}

.mobile-nav.active {
  transform: translateX(0%);
}

.mobile-nav .one {
  display: flex;
  justify-content: space-between;
}

.mobile-nav .one .one-right svg {
  font-size: 30px;
  color: #e0201c;
  cursor: pointer;
}

.product-details-page {
  padding: 20px 100px;
  /* padding-inline: 100px; */
}

.product-details-page .breadcrums {
  display: flex;
  margin-bottom: 20px !important;
  gap: 5px;
}

.product-details-page .breadcrums li:nth-child(1) {
  color: #3e3e3e;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.product-details-page .breadcrums li:nth-child(2) {
  color: #e0201c;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.product-details-page .img-product-details {
  width: 100%;
  padding-right: 20px;
  height: 70%;
}

.product-details-page .img-product-details img {
  width: 100%;
  border-radius: 15px;
  height: 95%;
  /* object-fit: cover;  */
}

.product-details-page .product-details-d {
  padding-left: 20px;
}

.product-details-page .product-name-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.product-details-page .product-details-d h2 {
  font-family: "Poppins", sans-serif;
  /* color: #3e3e3e; */
  color: rgba(0, 0, 0, 1);

  font-family: "Bebas Neue", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.product-details-page .product-name-con .right {
  padding: 4px 4px;
  background-color: #d9d9d9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.product-details-page .product-details-d .desc {
  color: rgba(230, 38, 43, 1);

  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 470px;
  margin-bottom: 13px !important;
}

.product-details-page .price {
  display: flex;
  margin-top: 15px;
  gap: 23px;
}

.product-details-page .price h2 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: center;
  color: #3e3e3e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-details-page .price h2 span {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: #e0201c;
  padding-left: 2px;
}

.product-details-page .counter {
  display: flex;
  width: 300px;
  height: 47px;
  align-items: center;
  /* justify-content: center; */
  /* border: 1px solid #d2d2d2; */
  border: 1px solid rgba(207, 207, 207, 1);
  border-radius: 6px;
  margin-top: 12px;
  box-shadow: 0.72px 0.72px 2.88px 0px rgba(0, 0, 0, 0.25);
  background-color: rgba(246, 246, 246, 1)
}

.product-details-page .counter .btn-1 {
  border-right: 1px solid #fff;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 17px; */
  height: 33px;
}

.product-details-page .counter p {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
}

.product-details-page .counter .btn-2 {
  border-left: 1px solid #fff;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 17px; */
  height: 33px;
}

.product-details-page .product-detail-cart {
  margin-top: 15px;
}

.product-details-page .product-detail-cart .fancy-button {
  font-size: 20px;
  text-align: center;
  color: white;
  line-height: 30px;
  width: -webkit-fill-available;
  width: -moz-available;
  white-space: nowrap;
  font-weight: 500;
  text-transform: uppercase;
  width: max-content;
  padding: 8px 62px 8px 62px;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  background-color: #e0201c;
}

.product-details-page .quantity-con {
  margin-top: 15px;
}

.product-details-page .quantity-con p {
  /* font-family: "Poppins", sans-serif; */
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 800;
  line-height: 16.34px;
  text-align: left;
  color: rgb(0, 0, 0, 1);
}

.product-details-page .bulk-order-con {}

.product-details-page .bulk-order-con p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #9d9d9d;
}

.product-details-page .bulk-order-con span {
  color: #3e3e3e;
}

.product-details-page .product-specification {
  margin-top: 50px;
}

.product-details-page .product-specification .heading {
  color: rgba(102, 102, 102, 1);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 4px;
}

.product-details-page .product-specification .description {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  /* color: #8e8e8e; */
  color: #3e3e3e;
  /* text-align: justify; */
}

.product-details-page .product-specification .product-field {
  /* border: 1px solid black; */
  border: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 20px;
  border-radius: 10px;
  margin-left: 20px;
  text-align: center;
  padding: 20px;
  background-color: rgba(217, 217, 217, 1);
}

.product-details-page .product-specification .product-field:hover {
  background-color: rgba(188, 22, 18, 1);
}

/* .product-details-page .product-specification .heading:hover {
  color: white;
}
.product-details-page .product-specification .tag:hover {
  color: white;
} */

.product-details-page .product-specification .product-field:hover .heading {
  color: #fff;
}

.product-details-page .product-specification .product-field:hover .description {
  color: #fff;
}

.product-details-page .product-specification .tag .product-details-page .product-specification .tag {
  display: inline-block;
  /* background-color: #e0201c; */
  /* background-color: rgba(224, 32, 28, 0.7); */
  background-color: (217, 217, 217, 1);
  color: rgba(102, 102, 102, 1);

  padding: 4px 9px;
  border-radius: 8px;
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 14px;
}

.product-details-page .product-specification .main-heading {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.product-details-page .video-box {
  width: 100%;
  /* max-width: 600px; */
  height: 100%;
  margin: 0 auto;
  margin-top: 50px;
}

.product-details-page .video-box video {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.product-details-page .login-con {
  margin-top: 15px;
}

.product-details-page .login-con p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #8e8e8e;
}

.product-details-page .login-con p .member-span {
  color: #3e3e3e;
}

.product-details-page .login-con p .login-span {
  text-decoration: underline;
  cursor: pointer;
}

.product-details-page .login-con p a {
  color: #8e8e8e;
}

.product-details-page .socail-con {
  margin-top: 10px;
  /* display: flex; */
  align-items: center;
  gap: 10px;
}

.product-details-page .socail-con p {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: rgba(102, 102, 102, 1)
}

.product-details-page .socail-con svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
}


.product-details-page .color-con-main {
  margin-top: 10px;
  /* display: flex; */
  align-items: center;
}

.product-details-page .color-con-main p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #3e3e3e;
  padding-right: 10px;
}

.product-details-page .color-con {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}

.product-details-page .color-con-main .red {
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: red;
  cursor: pointer;
}

.product-details-page .color-con-main .blue {
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: blue;
  cursor: pointer;
}

.product-details-page .color-con-main .orange {
  width: 18px;
  height: 18px;
  border-radius: 100px;
  background-color: orange;
  cursor: pointer;
}

.product-details-page .color-con .active-color {
  border: 2px solid;
}

.product-details-page .black-active {
  border: 2px solid red;
}

.product-details-page .variation-item-con {
  display: flex;
  gap: 14.8px;
  margin-bottom: 15px;
}

.variation-item-con .one {
  background-color: #f5f5f5;
  border-radius: 7.2px;
  padding: 10px 10px;
  cursor: pointer;
}

.variation-item-con .one.active {
  background-color: #e0201c;
}

.variation-item-con .one.active p {
  color: #fff;
}

.variation-item-con .one p {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

/* .variation-cart .variation-item-con .one.active {
  background-color: #e0201c;
} */
/* .variation-cart .variation-item-con .one.active p {
  color: #fff;
} */
.product-details-page .detail-img-slider {
  position: relative;
}

.product-details-page .detail-img-slider .img-con {
  display: flex;
  justify-content: start;
}

.product-details-page .detail-img-slider .img-con img {
  /* object-fit: cover; */
  cursor: pointer;
  width: 150px;
  height: 100px;
  border-radius: 6px;
  margin-bottom: 50px;
}

.product-details-page .detail-img-slider .detail-img-slider-button-parent {}

.product-details-page .detail-img-slider .detail-img-slider-button-parent .home-slider-prev {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.product-details-page .detail-img-slider .detail-img-slider-button-parent svg {
  font-size: 25px;
  cursor: pointer;
  color: #e0201c;
}



.product-details-page .detail-img-slider .detail-img-slider-button-parent .home-slider-next {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  z-index: 10;
}

#jtc-token {
  padding-inline: 50px;
  margin-top: 40px;
}

#jtc-token .heading-bg {
  /* background-color: #333333; */
  border-radius: 15px;
  height: 81px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/public/assets/images/token-bg.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: initial;
  margin-bottom: 39px;
}

#jtc-token .heading-bg h2 {
  font-family: "Poppins", sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(0, 0, 0, 1);

  margin: 0;
}

#jtc-tokenn {
  /* padding-inline: 50px; */
  margin-top: 40px;
}

#jtc-tokenn .heading-bg {
  background-color: #333333;
  border-radius: 15px;
  height: 81px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/public/assets/images/token-bg.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: initial;
  margin-bottom: 39px;
}

#jtc-tokenn .heading-bg h2 {
  font-family: "Poppins", sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  margin: 0;
}

#profile {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
}

.container #profile .input-parent {
  width: 100%;
  max-width: 550px;
}

#profile .img-con {
  position: relative;
  margin-bottom: 32px;
}

#profile .img-con .profile-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

#profile .img-con .profile-wrapper .btn-wrapper {
  position: relative;
  /* position: relative; */
}

#profile .img-con .profile-wrapper img {
  border-radius: 100%;
  object-fit: cover;
  width: 160px;
  height: 164px;
}

#profile .img-con .profile-wrapper .btn-wrapper .edit {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

#profile .input-parent .one {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 15px;
}

#profile .input-parent label {
  color: #a7a6a6;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

#profile .input-parent .input-wrapper {
  position: relative;
  width: 100%;
}

#profile .input-parent input {
  background-color: #f5f5f5;
  border-radius: 5px;
  width: 100%;
  min-width: 435px;
  padding: 18px 46px;
  border: none;
  outline: none;
}

#profile .react-tel-input .form-control {
  padding: 30px 46px !important;
  outline: none !important;
}

#profile .react-tel-input .flag-dropdown {
  border: none !important;
}

#profile .react-tel-input .selected-flag {
  padding: 0 0 0 15px !important;
}

#profile .input-parent .input-wrapper img {
  position: absolute;
  left: 16px;
  transform: translateY(-50%);
  top: 50%;
}

#profile .btn-con {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 55px;
}

#profile .btn-con button:nth-child(1) {
  /* border: 3px solid #e0201c; */
  padding: 18px 38px;
  border-radius: 10px;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  width: -webkit-fill-available;
  background-color: rgba(0, 0, 0, 1);

}

#profile .btn-con button:nth-child(2) {
  margin-top: 30px;
  padding: 18px 38px;
  border-radius: 10px;
  background-color: #e0201c;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

#profile .profile-wrap {
  max-width: 542px;
  margin-inline: auto;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  padding: 18px;
  border-top: 20px solid #e0211d;
}

.css-13cymwt-control {
  width: 100%;
  border-radius: 10px !important;
  background-color: #f8f8f8 !important;
  padding: 8px !important;
  border: none;
  position: relative;
  z-index: 1;
  cursor: pointer;
  outline: none !important;
  border: none !important;
}

.no-order {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: #3e3e3e;
}

.no-order-fav {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: #3e3e3e;
  position: absolute;
}

/*---------------------- CART SCREEN CSS START -----------------------------------------------  */
#cart {
  margin-top: 40px;
  padding-inline: 60px;
}

#cart .cart-wrapper .cart-img {
  width: 130px;
  height: 150px;
  object-fit: contain;
  border-radius: 10px;
}

#cart .main-wrapper {
  display: flex;
  gap: 106px;
}

#cart .main-wrapper .main-parent {
  flex: 2;
  display: flex;
}

#cart h1 {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 17px !important;
}

#cart .left .cart-wrapper {
  display: flex;
  gap: 36px;
  justify-content: space-between;
}

#cart .left {
  display: flex;
  flex-direction: column;
  gap: 34px;
  flex: 1.5;
}

#cart .left .up {
  margin-bottom: 21px;
}

#cart .left .up h3 {
  color: rgba(230, 38, 43, 1);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
}

#cart .left .up p {
  color: rgba(102, 102, 102, 1);
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 212px;
}

#cart .left .up .product-variation {
  margin-top: 8px;
}

#cart .left .up .product-variation span {
  background-color: #e0201c;
  color: #fff;
  font-family: "poppins", sans-serif;
  font-size: 14px;
  border-radius: 20px;
  padding: 1px 6px;
}

#cart .left .down p {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

#cart .left .down p span {
  color: #8f8f8f;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

#cart .right {
  flex: 1;
}

#cart .cart-wrapper-wrapper .quantity-wrapper h2 {
  color: rgba(230, 38, 43, 1);
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 05px;
}

#cart .cart-wrapper-wrapper .quantity-con {
  display: flex;
  margin-top: 36px;
  gap: 10px;
  align-items: center;
}

#cart .one .quantity-wrapper .quantity-con {
  margin-top: 36px;
  display: flex;
  align-items: center;
  gap: 10px;
}

#cart .one .quantity-wrapper .quantity-con .qty {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

#cart .one .quantity-wrapper .quantity-con .counter {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

#cart .right .two h2 {
  color: rgba(0, 0, 0, 1);
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 15px !important;
}

#cart .quantity-wrapper .quantity-con .minus {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  /* padding: 16px 8px; */
  padding: 9px;
  border-radius: 4px;
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
}

#cart .quantity-wrapper .quantity-con .plus {
  background: #eaeaea;
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding: 9px;
  border-radius: 4px;
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

#cart .one .quantity-wrapper:nth-child(2) {
  margin-top: 34px;
}

#cart .one .quantity-wrapper:nth-child(3) {
  margin-top: 34px;
}

#cart .right .two .summary-wrapper {
  /* background-color: #f3f3f3; */
  border-radius: 10px;
  padding: 24px 20px;
}

#cart .right .two .summary-wrapper .one {
  display: flex;
  justify-content: space-between;
}

#cart .right .two .summary-wrapper .one .p1 {
  color: rgba(102, 102, 102, 1);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

#cart .right .two .summary-wrapper .one .p2 {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

#cart .right .two .summary-wrapper .one .free {
  color: #e0201c;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

#cart .right .two .summary-wrapper .down {
  display: flex;
  justify-content: center;
  margin-top: 21px;
}

#cart .right .two .summary-wrapper .down button {
  background: #e0201c;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 13px 17px;
  border-radius: 8px;
}

#cart .cart-wrapper-wrapper {
  /* display: flex;
  justify-content: space-between; */
}

#cart .add-more {
  padding: 13px;
  border: 2px solid #e0201c;
  width: 223px;
  border-radius: 10px;
  text-align: center;
  color: #e0201c;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}

#cart .clear-cart {
  background: #e0201c;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 5px 7px;
  border-radius: 8px;
  height: 33px;
}

#cart .delete-cart-price-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

#cart .delete-cart-price-wrapper .cart-delete {
  font-size: 18px;
  color: #e0201c;
  cursor: pointer;
  height: 30px;
  width: 30px;
  border: 1px solid rgba(230, 38, 43, 1);
  border-radius: 30px;
  padding: 02px;


}

/*---------------------- CART SCREEN CSS END -----------------------------------------------  */

.header ul .login-btn {
  color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 7px 14px;
  border: 2px solid #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

/*---------------------- CHECKOUT SCREEN CSS START -----------------------------------------------  */
#checkout {
  margin-top: 40px;
}

#checkout .checkout-wrapper-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

#checkout .checkout-wrapper-heading ul li {
  list-style: none;
}

#checkout .checkout-wrapper-heading .left ul {
  display: flex;
  align-items: center;
}

#checkout .checkout-wrapper-heading .left ul li:nth-child(1) {
  background-color: #e0201c;
  border-radius: 100px;
  width: 73px;
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#checkout .checkout-wrapper-heading .left ul li:nth-child(2) {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #e0201c;
}

#checkout .checkout-wrapper-heading .left ul li:nth-child(3) {}

#checkout .checkout-wrapper-heading .left ul {
  gap: 20px;
}

#checkout .checkout-wrapper-heading .right ul {
  display: flex;
  align-items: center;
  gap: 20px;
}

#checkout .checkout-wrapper-heading .center li {
  border: 4px solid #e0201c;
  width: 109px;
  list-style: none;
}

#checkout .checkout-wrapper-heading .right ul li:nth-child(1) {
  background-color: #f3f3f3;
  border-radius: 100px;
  width: 73px;
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#checkout .checkout-wrapper-heading .right ul li:nth-child(2) {
  color: #afafaf;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

#checkout .checkout-wrapper-heading .right.active ul li:nth-child(1) {
  background-color: #e0201c;
  border-radius: 100px;
  width: 73px;
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#checkout .checkout-wrapper-heading .right.active ul li:nth-child(1) svg path {
  fill: #fff;
}

#checkout .checkout-wrapper-heading .right.active ul li:nth-child(2) {
  color: #e0201c;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

#checkout .order-summary-wrapper {
  margin-top: 30px;
  width: 100%;
  margin-inline: auto;
  max-width: 550px;
}

#checkout .order-summary-wrapper .one label {
  color: rgba(0, 0, 0, 1);
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 7px;
  padding-left: 20px;
}

#checkout .order-summary-wrapper .one .input-con {
  position: relative;
  background-color: #f8f8f8;
}

#checkout .order-summary-wrapper .one:nth-child(1) {
  z-index: 2;
  position: relative;
}

#checkout .order-summary-wrapper .one:nth-child(2) {
  margin-top: 20px;
  z-index: 1;
  position: relative;
}

#checkout .order-summary-wrapper .one:nth-child(3) {
  margin-top: 20px;
}

#checkout .order-summary-wrapper .one:nth-child(4) {
  margin-top: 20px;
}

#checkout .order-summary-wrapper .one:nth-child(6) {
  margin-top: 49px;
  display: flex;
  justify-content: center;
}

#checkout .order-summary-wrapper .one:nth-child(5) {
  margin-top: 49px;
  display: flex;
  justify-content: center;
}

#checkout .order-summary-wrapper .one .location {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  z-index: 2;
}

#checkout .order-summary-wrapper .one .edit {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;
  z-index: 2;
}

#checkout .order-summary-wrapper .one input {
  width: 100%;
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 14px;
  padding-inline-start: 34px;
  border: none;
  margin-left: 15px !important;
}

#checkout .order-summary-wrapper .one .input-checkout-address {
  margin-left: 0px !important;
}

#checkout .order-summary-wrapper .one .promo {
  width: 100%;
  border-radius: 10px;
  background-color: #f8f8f8;
  padding: 14px;
  padding-inline-start: 15px;
  border: none;
}

#checkout .order-summary-wrapper .one input:focus-visible {
  outline: none;
}

#checkout .order-summary-wrapper .one select {
  width: 100%;
  border-radius: 10px;
  background-color: transparent;
  padding: 14px;
  border: none;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

#checkout .order-summary-wrapper .one select option {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

#checkout .order-summary-wrapper .one select:focus {
  border: none;
  outline: none;
}

#checkout .order-summary-wrapper .one select option::selection {
  border: none;
  outline: none;
}

/* select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
} */
#checkout .order-summary-wrapper .one select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px;
  z-index: 1;
}

#checkout .order-summary-wrapper .one .select-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  z-index: 0;
}

#checkout .order-summary-wrapper .one .con-summary {
  background-color: #f8f8f8;
  border-radius: 7px;
}

#checkout .order-summary-wrapper .one .con-summary .up {
  /* border-bottom: 1px dashed #c0c0c0; */
  padding: 13px;
}

#checkout .order-summary-wrapper .one .con-summary .up .two {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

#checkout .order-summary-wrapper .one .con-summary .up .p1 {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

#checkout .order-summary-wrapper .one .con-summary .up .p2 {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

#checkout .order-summary-wrapper .one .con-summary .down {
  padding: 13px;
  background-color: #fff;
}

#checkout .order-summary-wrapper .one .con-summary .down .two {
  display: flex;
  justify-content: space-between;
  padding: 2px;
  border-bottom: 1px dashed #c0c0c0;
  margin-top: 20px;
}

#checkout .order-summary-wrapper .one .con-summary .down .p3 {
  color: #9a9a9a;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
}

#checkout .order-summary-wrapper .one .con-summary .down .p4 {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

#checkout .order-summary-wrapper .one .con-summary .down .p5 {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
}

#checkout .order-summary-wrapper .one .checkout-btn {
  background-color: #e0201c;
  border-radius: 10px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 13px 67px;
}

#checkout .btn-wrapper {
  text-align: center;
  margin-top: 30px;
}

#checkout .btn-wrapper button {
  background-color: #e0201c;
  border-radius: 10px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 13px 67px;
}

#order-card-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 39px;
}

#order-card-parent .order-card {
  display: flex;
  gap: 195px;
}

#order-card-parent .order-card .left {
  display: flex;
  align-items: center;
  gap: 17px;
}

#order-card-parent .order-card .left .img-con {
  background-color: #f8f8f8;
  border-radius: 16px;
}

#order-card-parent .order-card .left .img-con img {
  padding: 26px 8px;
}

#order-card-parent .order-card .left .text-con p:nth-child(1) {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #3e3e3e;
}

#order-card-parent .order-card .left .product-variation-con {
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
  /* flex-direction: column; */
}

#order-card-parent .order-card .left .product-variation-con .productName {
  font-family: 'poppins', sans-serif;
  /* font-weight: 18px; */
  color: #3e3e3e;
  margin-top: 8px;
}

#order-card-parent .order-card .left .product-variation-con .product_price {
  font-family: 'poppins', sans-serif;
  /* font-weight: 18px; */
  color: #3e3e3e;
  font-size: 16px;
  margin-top: 4px;
}

#order-card-parent .order-card .left .product-variation-con p {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px !important;
  font-family: "poppins", sans-serif;
  color: #3e3e3e;
  font-weight: 500;
  flex-direction: column;
  align-items: start;
  gap: 0;
}

#order-card-parent .order-card .left .product-variation-con span {
  background-color: #e0201c;
  border-radius: 20px;
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  padding: 2px 6px !important;
  text-align: left;
}

#order-card-parent .order-card .left .text-con .one p:nth-child(1) {
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #3e3e3e;
}

#order-card-parent .order-card .left .text-con .one p:nth-child(2) {
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: right;
  color: #3e3e3e;
}

#order-card-parent .order-card .left .text-con .one {
  display: flex;
  justify-content: space-between;
}

#order-card-parent .order-card .right {
  display: flex;
  align-items: center;
}

#order-card-parent .order-card .right .preparing {
  background-color: #ffdcdd;
  color: #e0201c;
  padding: 4px 30px;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  min-width: 170px;
}

#order-card-parent .order-card .right .done {
  background-color: #d9ffe1;
  color: #00ca2c;
  padding: 4px 30px;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  min-width: 170px;
}

#order-card-parent .order-card .right .dispatched {
  background-color: #e6e6e6;
  color: #3e3e3e;
  padding: 4px 30px;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  min-width: 170px;
}

#order-card-parent .order-card .right .reject {
  background-color: #e0201c;
  color: #ffffff;
  padding: 4px 30px;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  min-width: 170px;
}

.img-address-profile {
  position: absolute;
  z-index: 1;
  top: 50%;
}

.cart-wrapper-inside {
  display: flex;
  gap: 12px;
}

#coupon-parent {
  /* display: flex;
  width: 90%;
  margin-inline: auto; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Two equal columns */
  gap: 6px 14px;

  max-width: 777px;
  margin-inline: auto;
}

.coupon-page {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
}

.reward-page {
  justify-content: space-between;
  align-items: start;
}

#coupon-parent .coupon.false {
  pointer-events: none;
  /* width: 100%; */
}

#coupon-parent .coupon.false h2,
#coupon-parent .coupon.false p,
#coupon-parent .coupon.false span {
  color: #a3a3a3 !important;
}

#coupon-parent .coupon {
  display: flex;
  align-items: center;
  /* background-color: #f8f8f8; */
  /* justify-content: space-between; */
  border-radius: 12px;
  transition: background-color 0.3s ease, border-radius 0.3s ease;
  border: 2px solid #fff;
  gap: 13px;
  /* width: 100%; */
  height: 85px;


  flex: 1 1 45%;
  box-sizing: border-box;
  padding: 16px;
}

#coupon-parent .coupon:nth-child(4n),
.coupon:nth-child(4n-1) {
  background-color: white;
}

#coupon-parent .coupon:nth-child(4n-2),
.coupon:nth-child(4n-3) {
  background-color: #f8f8f8;
}

#coupon-parent .coupon .img2-false {
  display: none;
}

#coupon-parent .coupon.false .img1-disable {
  display: none;
}

#coupon-parent .coupon.disable .img2-disable {
  display: block;
}

/* #coupon-parent .coupon:hover {
  border: 2px solid #e0201c;
  box-shadow: 7px 7px 12px 0px #00000040;
  border-radius: 12px;
} */

#coupon-parent .coupon .left {
  /* background-color: #ffffff; */
  /* border-radius: 12px; */
}

#coupon-parent .coupon .left img {
  /* padding: 50px 10px; */
}

#coupon-parent .coupon .right {
  /* background-color: #f8f8f8; */
  /* border-radius: 0px 10px 10px 0px; */
}

#coupon-parent .coupon .right .one {
  /* padding: 19px 30px 12px 47px; */
  /* padding: 15px 25px; */
  /* padding: 16px; */
}

#coupon-parent .coupon .right .one h2 {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

#coupon-parent .coupon .right .one p:nth-child(1) {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

#coupon-parent .coupon .right .one p:nth-child(2) {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

#coupon-parent .coupon .right .one p:nth-child(2) {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

#coupon-parent .coupon .right .one p:nth-child(3) {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

#coupon-parent .coupon-wrapper .main-coupon {
  margin-bottom: 37px;
  max-width: 470px;
  width: 100%;
}

#coupon-parent .coupon-wrapper .main-coupon .time {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #3e3e3e;
  margin-bottom: 11px !important;
}

#coupon-parent .reward-left {
  flex: 1;
}

#coupon-parent .reward-right {
  flex: 1;
}

#coupon-parent .coupon-wrapper {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#coupon-parent .reward-right {
  background-color: #f6f6f6;
  padding: 24px 5px 14px 5px;
  border-radius: 10px;
}

#coupon-parent .reward-right .up {
  text-align: center;
}

#coupon-parent .reward-right .down {
  background-image: url("/public/assets/images/reward-bg.png");
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

#coupon-parent .reward-right .down h1 {
  color: #3e3e3e;
  font-family: "Bebas";
  font-size: 80px;
  font-weight: 400;
  line-height: 96px;
  letter-spacing: 0em;
  display: flex;
  flex-direction: column;
}

#coupon-parent .reward-right .down h1 span {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  margin-top: -10px;
}

#coupon-parent .coupon .right .two {
  display: flex;
  justify-content: space-between;
}

#coupon-parent .coupon-wrapper .main-coupon .coupon .right .two p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #3e3e3e;
}

#coupon-parent .coupon-wrapper .main-coupon .coupon .right .one .earn-reward {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #3e3e3e;
  margin-top: 5px !important;
}

#coupon-parent .coupon-wrapper .main-coupon .coupon .right .one .earn-reward span {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #3e3e3e;
}

.wrapper-location-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.btn-use-current-loc {
  background-color: #e0201c;
  color: #fff;
  padding: 10px 12px;
  border-radius: 8px;
}

.btn-use-current-loc-back {
  background-color: #e0201c;
  color: #fff;
  padding: 10px 50px;
  border-radius: 8px;
  font-size: 16px;
}

.order-summary-wrapper .up .two .p1 {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.order-summary-wrapper .up .two .val {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  justify-content: space-between;
  /* gap: 10px; */
  /* width: 60px; */
}

.order-summary-wrapper .up .two .val span {
  color: #e0201c;
  font-size: 14px;
}

.mobile-logo {
  display: none;
}

/* .input-search-con-mobile{
  display: none;
} */
.search-mobile-input {
  display: none;
}

.btn-loadmore {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.btn-loadmore button {
  padding: 8px 30px;
  border-radius: 8px;
  background-color: #e0201c;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 16px;
}

.shop-card-con .card-category-con {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 25px !important;
}

.pagination-con {
  margin-top: 30px;
}

.pagination-con .pagination {
  justify-content: center !important;
}

.pagination-con .page-link {
  color: #e0201c;
}

.pagination-con .active>.page-link,
.page-link.active {
  background-color: #e3c6c6;
  border: 1px solid #e0201c;
}

.pagination-con .page-item.active .page-link {
  color: #e0201c;
}



.qwert {
  background: rgba(230, 38, 43, 1);
  height: 20px;
  width: 20px;
  border: 1px solid red;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: antiquewhite;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 900;
  line-height: 6px;
  text-align: center;

}

.banner-slider-con {
  overflow: hidden;
  padding-inline: 60px;
  margin-top: 50px;
}

.banner-slider-con .slider-wrap {
  background-image: url('/public/assets/images/slider-img.png');
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  padding-left: 104px;
}

.image-split{
  text-align: center;
  display: flex;
  justify-content: center;
}


.banner-slider-con .slider-wrap h3 {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  text-align: left;
  max-width: 400px;
}

/* ////////////////////New Order////////////////////// */
/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.neworder-container {
  text-align: center;
  padding: 40px 20px;
  padding-inline: 60px;
}

.neworder-title {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 700;
  line-height: 46px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 40px;
}

.new-or {
  height: 20px;
  width: 20px;
  background-color: rgba(224, 34, 30, 1);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.totalAmount {
  color: red;
}

.neworder-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.neworder-list .productlistcon {
  padding-bottom: 16px;
}

.neworder-card {
  width: 400px;
  padding: 15px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  /* height: 455px; */
  height: 375px;
}

.neworder-card.expand {
  height: auto;
}


.neworder-card .show-more-button {
  font-family: "Poppins", sans-serif;
  color: #e0201c;
  cursor: pointer;
}




.neworder-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 6px;
}

.neworder-logo {
  width: 60px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(224, 34, 30, 1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.neworder-info {
  text-align: left;
}

.neworder-id {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}

.neworder-details {
  font-family: Poppins;
  font-size: 10px;
  color: #777;
  display: flex;
  align-items: center;
  display: inline-grid;



}

.neworder-icon {
  /* margin-right: 5px; */
  color: white;
}

.neworder-content {
  margin-top: 10px;
}

.neworder-item-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.neworder-item-image {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 10px;
}

.neworder-item-info {
  flex: 1;
  text-align: left;
}

.neworder-item-qty {
  font-size: 0.8rem;
  color: #888;
}

.neworder-item-price {
  font-size: 1.2rem;
  color: #ff3b30;
  font-weight: bold;
}

.neworder-status {
  font-weight: bold;
  color: white;
  background-color: green;
  padding: 5px 10px;
  border-radius: 5px;
  width: fit-content;
  margin: 10px 0;
}

.neworder-status-accepted {
  background-color: #4caf50;
}

.neworder-summary p {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  /* color: #444; */
}

.neworder-summary span {
  font-weight: 500;
  color: #404040;
}

.neworder-grand-total {
  font-weight: bold;
  color: #ff3b30;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 25px; */
}

.neworder-view-btn {
  background-color: #ff3b30;
  color: white;
  /* padding: 12px; */
  /* width: 100%; */
  width: 256px;
  height: 57px;
  border: none;
  border-radius: 11px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.neworder-view-btn:hover {
  background-color: #e02d26;
}

.neworder-logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
}





