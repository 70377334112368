@media screen and (max-width: 1001px) {
  #cart .main-wrapper {
    flex-direction: column-reverse;
  }



  
}
@media screen and (max-width: 991px) {
  .header {
    padding-inline: 20px;
    margin-top: 25px;
  }
  .header .left {
    align-items: start;
  }
  .banner-sec .left {
    padding-inline: 30px;
    margin-bottom: 50px;
  }
  .banner-sec .right {
    padding-inline: 30px;
  }
  .banner-sec .left h2 {
    margin-bottom: 25px;
  }
  .header ul li a {
    font-size: 16px;
    font-weight: 300;
    text-decoration: none;
    color: #fff;
    font-family: "Poppins", sans-serif;
  }

/* //////////// */
.header .right ul{
  display: none;
}
.mobile-logo{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.header .left{
  display: none;
}
/* .hamburger-mobile{
display: block;
}

.header .hamburger-mobile {
  display: block;
} */
/* 
.header .hamburger-mobile ul {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0;
}
.header .hamburger-mobile .one {
  display: flex;
  gap: 8px;
}
.header .hamburger svg {
  font-size: 30px;
  color: #e0201c;
  cursor: pointer;
} */


.header .hamburger-mobile {
    display: block;
  }
  .header .hamburger-mobile ul {
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 0;
  }
  .header .hamburger-mobile .one {
    display: flex;
    gap: 8px;
  }
  .header .hamburger svg {
    font-size: 30px;
    color: #e0201c;
    cursor: pointer;
  }

/* /////// */


  
  
  .shop-card-con .card-con {
    grid-template-columns: repeat(3, 1fr);
  }
  .header .right .input-search-con .search-input {
    min-width: 150px;
  }
  .header .right ul {
    gap: 20px;
  }
  .shop-card-con .marleft {
    width: 100%;
  }
  .shop-card-con .filter-con {
    width: 100%;
  }
  .shop-card-con .filter-con {
    box-shadow: none;
    display: block;
    margin-bottom: 15px;
    position: static;
  }
}
@media screen and (max-width: 768px) {

.neworder-card {
  height: auto;
}
  
  #coupon-parent{
    grid-template-columns: repeat(1,1fr);
  }
  #coupon-parent .coupon{
    height: auto;
    background-color: #f8f8f8 !important;
  }
  #jtc-token{
    margin-top: 0px;
  }
  #jtc-token .heading-bg{
    margin-bottom: 0px;
  }
  .header .language-converter{
    margin-top: 20px;
  }
  .shop-card-con .card-category-con {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .shop-card-con .marleft {
    width: 100%;
  }
  .shop-card-con .filter-con {
    width: 100%;
  }
  .shop-card-con .filter-con {
    box-shadow: none;
    display: block;
    margin-bottom: 15px;
    position: static;
  }
  #after-login-mobile ul li ul a {
    text-decoration: none;
    color: #2b2b2b;
    font-weight: 800;
  }
  .dropdown-menu {
    position: relative;
    background-color: transparent;
    border-radius: 10px;
  }
  .dropdown-menu .container {
    position: absolute;
    background-color: #fff;
    width: 60vw;
    /* margin-inline: auto; */
    height: 400px;
    overflow-y: auto;
    border-radius: 10px;
    left: 40%;
    transform: translateX(-50%);
  }
  .header {
    display: block;
  }
  .mobile-logo {
    display: flex;
    justify-content: space-between;
  }
  .search-mobile-input {
    display: block;
  }
  .input-search-con-mobile {
    display: block;
    padding-inline: 20px;
    position: relative;
  }
  .search-mobile-input .search-results {
    position: absolute;
    /* top: 100%; */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    /* max-width: 300px; */
    width: 90vw;
    padding: 8px;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 8px;
    border: 1px solid #f1efef;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 200px;
    overflow-y: auto;
  }
  .search-mobile-input .search-results .no-data-found {
    font-family: "Poppins", sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .search-mobile-input .search-results .search-item {
    display: flex;
    justify-content: start;
    margin-bottom: 8px;
    padding: 5px 3px 3px 3px;
    gap: 8px;
  }
  .search-mobile-input .search-results .search-item .image-container img {
    width: 30px;
    border-radius: 8px;
    height: 30px;
  }
  .search-mobile-input .search-results .search-item .product-info h3 {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #3e3e3e;
  }
  .search-mobile-input .search-results .search-item .product-info p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: #3e3e3e;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .input-search-con-mobile input {
    border: 1px solid #d4d4d4;
    background-color: rgba(249, 250, 251, 1);
    padding: 6px 18px;
    /* padding-inline-start: 40px; */
    border-radius: 6px;
    outline: none;
    width: 100%;
  }
  .banner-slider-con .slider-wrap h3{
    font-size: 20px;
    text-align: center;
    line-height: 40px;
  }


  #checkout .order-summary-wrapper {
    width: 100%;
    padding-inline: 20px;
  }
  #checkout .order-summary-wrapper .one .con-summary .down {
    padding: 0;
  }
  #checkout .checkout-wrapper-heading .left ul {
    gap: 8px;
  }
  #checkout .checkout-wrapper-heading {
    gap: 8px;
  }
  #checkout .checkout-wrapper-heading .left ul li:nth-child(2) {
    font-size: 18px;
  }
  #checkout .checkout-wrapper-heading .left ul li:nth-child(1) {
    width: 50px;
    height: 50px;
  }
  #checkout .checkout-wrapper-heading .left ul li:nth-child(1) img {
    width: 20px;
  }
  #checkout .checkout-wrapper-heading .right ul li:nth-child(1) {
    width: 50px;
    height: 50px;
  }
  #checkout .checkout-wrapper-heading .right ul li:nth-child(1) svg {
    width: 30px;
  }
  #checkout .checkout-wrapper-heading .right ul li:nth-child(2) {
    font-size: 18px;
  }
  #cart {
    padding-inline: 20px;
  }
  .header {
    padding-inline: 20px;
  }
  .banner-sec {
    padding: 40px 0px;
  }
  .header .left {
    display: none;
  }
  .header .right {
    display: none;
  }
  /* .header .hamburger-mobile {
    display: block;
  }
  .header .hamburger-mobile ul {
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 0;
  }
  .header .hamburger-mobile .one {
    display: flex;
    gap: 8px;
  }
  .header .hamburger svg {
    font-size: 30px;
    color: #e0201c;
    cursor: pointer;
  } */

  .product-details-page {
    padding-inline: 30px;
  }
  .product-details-page .img-product-details {
    padding-right: 0;
    width: 100%;
  }
  .product-details-page .product-details-d {
    padding-left: 0;
    margin-top: 30px;
  }
  .product-details-page .product-specification {
    margin-top: 40px;
  }
  .product-details-page .product-specification .heading {
    margin-top: 20px;
    /* margin-bottom: 12px; */
  }
  .product-details-page .product-specification .col-lg-4.col-md-4.col-12 {
    padding: 0;
  }
  .mobile-nav {
    display: block !important;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #3e3e3e;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    transform: translateX(100%);
    transition: transform 0.5s linear;
    padding: 30px;
  }
  .mobile-nav .two {
    margin-top: 36px;
    height: calc(100vh - 93.64px);
    display: flex;
    justify-content: center;
    align-items: start;
  }
  .mobile-nav .two ul li {
    text-align: center;
    font-size: 20px;
    color: #f6f6f6;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 20px;
  }
  .mobile-nav .two ul li a {
    font-size: 16px;
    font-weight: 300;
    text-decoration: none;
    color: #fff;
    font-family: "Poppins", sans-serif;
  }
  .header .mobile-nav .after-login {
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 7px 14px;
    border: 2px solid #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .header .mobile-nav .dd-options,
  .header .right ul li .dd-options {
    border-radius: 8px;
    background: #fff;
    box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    min-width: 250px;
    right: -22%;
    display: none;
    top: 110%;
    z-index: 10;
  }
  .header .mobile-nav .dd-options.show,
  .header .right ul li .dd-options.show {
    display: inline;
  }
  .header .mobile-nav .dd-options ul,
  .header .right ul li .dd-options ul {
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: start;
  }
  .header .mobile-nav .dd-options ul li:first-child,
  .header .right ul li .dd-options ul li:first-child {
    border-radius: 8px 8px 0px 0px;
  }
  .header .mobile-nav .dd-options ul li,
  .header .right ul li .dd-options ul li {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    width: 99%;
    gap: 9px;
    border-bottom: 1px solid #e1e1e1;
    cursor: pointer;
    background: #fff;
    margin-top: 0px;
  }
  .header .mobile-nav .dd-options ul li a {
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    gap: 20px;
  }
  .header .mobile-nav .dd-options ul li .dd-options-right h4,
  .header .right ul li .dd-options ul li .dd-options-right h4 {
    color: #3e3e3e;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }
  .header .mobile-nav .dd-options ul li .dd-options-right p,
  .header .right ul li .dd-options ul li .dd-options-right p {
    color: #aaa;
    font-family: "Poppins";
    font-size: 9.6px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .shop-card-con .head-con {
    /* padding-inline: 20px; */
  }
  .shop-card-con .head-con {
    justify-content: start;
  }
  .shop-card-con .card-con {
    grid-template-columns: repeat(1, 1fr);
    padding-inline: 12px;
  }
  .header .logout-btn {
    text-align: center;
  }
  .header .dd-options ul li:last-child {
    display: flex;
    justify-content: center;
  }
  .header .logout-btn button {
    border-radius: 8px;
    background: #e0201c;
    color: #fff;
    font-family: "Poppins";
    font-size: 11.2px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 10px 50px;
  }
  .shop-card-con .head-con h2 {
    font-size: 30px;
  }
  .shop-card-con .head-con .filter-selection {
    /* align-items: center;
    gap: 10px; */
  }
  .product-details-page .right-main{
    margin-top: 40px ;
  }
  .product-details-page .detail-img-slider .img-con{
    width: 100%;
  }
  .product-details-page .detail-img-slider .img-con img{
    width: 100%;
  }
  .banner-slider-con{
    padding-inline:20px;
  }
  .banner-slider-con .slider-wrap{
    flex-direction: column;
    padding-left: 0px;
    padding: 8px;
  }
  .banner-slider-con .slider-wrap h3{
    font-size: 20px;
    text-align: center;
  }
  .slick-slide img{
    width: 80%;
  }
}
@media screen and (max-width: 550px) {
  #jtc-token {
    padding-inline: 20px;
  }
  #profile .input-parent input {
    min-width: 0px;
  }
  #order-card-parent {
    display: block;
    /* justify-content: center; */
    /* flex-direction: column; */
    /* align-items: center; */
    gap: 39px;
  }
  #profile .input-parent {
    max-width: none;
  }
  #cart .left .cart-wrapper {
    flex-direction: column;
  }
  .product-details-page {
    padding-inline: 20px;
  }
  .product-details-page .product-specification .product-field {
    margin-bottom: 0;
  }
  #cart .cart-wrapper-wrapper {
    flex-direction: column;
    gap: 30px;
  }
  s .header {
    padding-inline: 20px;
  }
  .banner-sec .left {
    padding-inline: 20px;
  }
  .banner-sec .right {
    padding-inline: 20px;
  }
  .shop-card-con .head-con .filter-selection {
    /* flex-direction: column;
    gap: 0;
    align-items: start; */
  }
  .shop-card-con .head-con {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 425px) {
 
  .row{
    gap: 10px;
    justify-content: center;
  }
  #jtc-token {
    padding-inline: 10px !important;
  }
  #jtc-tokenn {
    padding-inline: 10px !important;
  }

  #profile .img-con .profile-wrapper img {
    height: auto;
    width: 100%;
  }
  #profile .input-parent {
    width: 100%;
  }
  #profile .input-parent .input-wrapper {
    width: 100%;
  }
  #profile .input-parent input {
    min-width: 0;
    padding: 18px 35px;
  }
  #profile .btn-con {
    margin-top: 30px;
  }
  #profile .btn-con button:nth-child(1) {
    padding: 12px 38px;
  }
  #profile .btn-con button:nth-child(2) {
    padding: 12px 38px;
    margin-top: 15px;
  }
  #cart .main-wrapper {
    gap: 40px;
  }
  #cart .cart-wrapper .cart-img {
    width: 100%;
  }
  #cart .cart-wrapper .cart-img {
    width: 100%;
  }
  .cart-wrapper-inside {
    flex-direction: column;
  }
  #cart .left .cart-wrapper {
    flex-direction: column;
  }
}
@media screen and (max-width: 380px) {
  .shop-card-con .card-con {
    grid-template-columns: repeat(1, 1fr);
  }
  .shop-card-con .head-con h2 {
    font-size: 25px;
  }
  #cart .right .two .summary-wrapper .down button {
    padding: 10px;
    font-size: 15px;
  }



}
