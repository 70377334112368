.add-to-cart-modal .modal-dialog {
  width: 358px !important ;
}
.add-to-cart-modal .modal-content {
  border: none !important;
  border-radius: 10px !important;
}

.add-to-cart-modal .modal-body .counter {
  display: flex;
  width: 162px;
  height: 33px;
  align-items: center;
  /* justify-content: center; */
  border: 1px solid #d2d2d2;
  border-radius: 22px;
  margin-top: 10px;
  margin-inline: auto;
}
.add-to-cart-modal .modal-body .counter .btn-1 {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 17px; */
  border-right: 1px solid #d2d2d2;
  height: 33px;
}
.add-to-cart-modal .modal-body .counter p {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
}

.add-to-cart-modal .modal-body .counter .btn-2 {
  border-left: 1px solid #d2d2d2;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 17px; */
  height: 33px;
}
.add-to-cart-modal .custom-header {
  display: flex;
  padding: 18px 27px 0px 27px;
  gap: 112px;
}
.add-to-cart-modal .cart-btn {
  width: 100%;
  background: #e0201c;
  display: flex;
  justify-content: center;
  padding: 9px 30px;
  color: #fff;
  border-radius: 0px 0px 10px 10px;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.verify-otp-modal {
}
.verify-otp-modal .modal-dialog {
  width: 501px;
}
.verify-otp-modal .modal-content {
  border: none;
  border-radius: 15px;
  position: relative;
}
.verify-otp-modal .modal-body {
  padding: 0;
}
.verify-otp-modal .modal-body .up {
  background-image: url("/public/assets/images/otp-bg.png");
  background-repeat: no-repeat;
  background-color: #242424;
  background-size: contain;
  height: 124px;
  border-radius: 11px 11px 0px 0px;
  padding: 52px 50px 5px 50px;
}
.verify-otp-modal .modal-body .up h3 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
}
.verify-otp-modal .modal-body .up p {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.verify-otp-modal .modal-body .down {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
}
.verify-otp-modal .modal-body .down .one {
  display: flex;
  align-items: center;
  gap: 8px;
}
.verify-otp-modal .modal-body .down .one p {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
}
.verify-otp-modal .modal-body .down .one .edit-con {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.verify-otp-modal .modal-body .down .one .edit-con p {
  color: #e0201c;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
}
.verify-otp-modal .modal-body .down .two {
  margin-top: 21px;
  width: 80%;
}

.verify-otp-modal .modal-body .down .two .vi__character {
  border: 2px solid #d4d4d4;
  border-radius: 10px;
  font-size: 24px;
  line-height: 29px;
  padding: 8px;
  background-color: #f5f5f5;
  margin-inline: 5px;
}

.verify-otp-modal .modal-body .down .two .vi__character:focus-visible {
  outline: none !important;
}

.verify-otp-modal
  .modal-body
  .down
  .two
  .vi__character.vi__character--selected {
  outline: none !important;
  border: 1px solid #e0201c;
  color: #e0201c;
}
.verify-otp-modal
  .modal-body
  .down
  .two
  .vi__character.vi__character--inactive {
  border: 2px solid #d4d4d4;
  border-radius: 10px;
  font-size: 24px;
  line-height: 29px;
  padding: 8px;
  background-color: #f5f5f5;
}
.verify-otp-modal .modal-body .down .three {
  width: 100%;
  margin-top: 31px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.verify-otp-modal .modal-body .down .three p {
  font-family: "poppins",sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #21132f;
  margin-bottom: 23px !important;
}
.verify-otp-modal .modal-body .down .three span {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #e0201c;
  cursor: pointer;
}

.verify-otp-modal .modal-body .down .three button {
  max-width: 65%;
  width: 100%;
  margin-bottom: 31px;
  padding: 16px;
  border-radius: 10px;
  background-color: #e0201c;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.verify-otp-modal .cross-sign-container {
    position: absolute;
    z-index: 1;
    right: 0;
    padding: 10px;
}
.verify-otp-modal .cross-sign-container svg {
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}
.img-wrapper-checkout img {
  width: 80px;
}
.checkout-head {
  font-family: "Poppins", sans-serif;
  color: #000;
  font-size: 25px;
  font-weight: 500;
  line-height: 60px;
}
.order-summary {
  padding: 12px;
  border-radius: 12px;
  background-color: #f8f8f8;
} 
.detail-checkout {
  background: #f8f8f8;
  border-radius: 12px;
  padding: 12px;
}
.main-head {
  font-family: "poppins", sans-serif;
  font-size: 16px;
  color: #3e3e3e;
  font-weight: 600;
  color: #3e3e3e;
  margin-bottom: 8px;
}
.order-summary-wrapper .up .two {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.card-num-head {
  font-family: "poppins", sans-serif;
  font-size: 16px;
  color: #3e3e3e;
}
.btn-pay {
  background-color: #e0201c !important;
  outline: none;
  border: none;
  color: #fff !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}
.location-modal .modal-lg {
  width: 390px;
}
.location-modal .modal-content img {
  width: 100%;
}
.location-modal .modal-content p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: #3e3e3e;
  margin-top: 30px !important;
}
.location-modal .modal-content .down {
  margin-bottom: 50px !important;
}
.location-modal .modal-content .down .btn-wrapper-loc {
  display: flex;
  justify-content: center;
  margin-bottom: 10px !important;
}
.location-modal .modal-content .down .btn-wrapper-loc button {
  padding: 15px 45px;
  border-radius: 10px;
  color: #fff;
  background-color: #e0201c;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}
.location-modal .modal-content .down .input-wrapper {
  display: flex;
  justify-content: center;
}
.location-modal .modal-content .down .input-wrapper input {
  border: none;
  outline: none;
  text-align: center;
}
.location-modal .modal-content .down .input-wrapper ::placeholder {
  color: #3e3e3e !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}


.ty-modal {
  --bs-modal-width: 422px;
}
.ty-modal .modal-dialog {
  width: 422px;
}
.ty-modal .modal-content {
  padding: 0px;
  border-radius: 16px;
}
.ty-modal .modal-body {
  padding: 0;
  margin-top: 33px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.ty-modal .modal-content {
  overflow: hidden;
}
.ty-modal .modal-body h3 {
  margin-top: 40px !important;
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}
.ty-modal .modal-body a {
  margin-top: 40px !important;
  padding: 13px 74px;
  background-color: #e0201c;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 10px;
  margin-bottom: 32px !important;
}


@media screen and (max-width: 482px) {
  
  .ty-modal .modal-dialog{
    width: 95%;
    margin-inline: auto;
  }

  .ty-modal .modal-body img{
    width: 100%;
  }

  .ty-modal .modal-body h3,
  .ty-modal .modal-body a {
    margin-top: 20px !important;
}
}